body {
  margin: 0;
  padding: 1rem 0.5rem;
  line-height: 1.4;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: yellow;
  background-image: linear-gradient(
    225deg,
    hsl(52deg 100% 50%) 0%,
    hsl(48deg 100% 50%) 11%,
    hsl(44deg 100% 50%) 22%,
    hsl(41deg 100% 50%) 33%,
    hsl(37deg 100% 50%) 44%,
    hsl(33deg 100% 50%) 56%,
    hsl(29deg 100% 50%) 67%,
    hsl(24deg 100% 49%) 78%,
    hsl(20deg 100% 48%) 89%,
    hsl(14deg 98% 48%) 100%
  );
  padding-bottom: 100px;
}

a {
  color: inherit;
  font-weight: 700;
}

h1 {
  font-size: 2.5rem;
  color: deeppink;
  animation: pulse infinite alternate 1s;
  text-shadow: -18px 0 lightblue, 0 18px lightblue, 18px 0 lightblue, 0 -18px lightblue;
  max-width: 100%;
  height: 4rem;
  max-height: 4rem;
  overflow: none;
  margin-top: 64px;
}

@keyframes pulse {
  from {
    transform: scale(1) rotate(15deg);
  }
  to {
    transform: scale(1.1) rotate(15deg);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  font-family: "Monoton", "Ultra", serif;
}

nav {
  margin: 0 auto;
  width: fit-content;
}
